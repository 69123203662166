const tailingDotTypes = ["NS", "MX", "CNAME", "PTR", "SRV"];

function isTailingDotType(type) {
  type ||= '';
  return tailingDotTypes.includes(type.toUpperCase());
}
export {
  tailingDotTypes,
  isTailingDotType,
};
