<template>
  <v-card :loading="loading">
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title v-if="domain != null">
        {{ domain.domain_name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="dialogCancel = !dialogCancel"
            :disabled="loadingCancel || !canCancel || dialogCancel || isBlocked"
            :loading="loadingCancel"
            icon
          >
            <v-icon>mdi-progress-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("cancel termination") }}</span>
      </v-tooltip>
      <v-tooltip top v-if="domain != null && domain.product_id">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="dialogTransferCode = !dialogTransferCode"
            :disabled="
              loadingTransferCode ||
              !canTransfer ||
              dialogTransferCode ||
              isBlocked
            "
            :loading="loadingTransferCode"
            icon
          >
            <v-icon>mdi-transfer-right</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("request transfer code") }}</span>
      </v-tooltip>
      <v-tooltip top v-if="domain != null && domain.product_id">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              $router.push({
                name: 'product-details',
                params: { productId: domain.product_id },
              })
            "
            :disabled="loading || domain == null || domain.product_id == null"
            icon
          >
            <v-icon>mdi-text-box</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("open product") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :loading="loading"
            :disabled="loading || domain == null"
            @click="getDomain"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload domain") }}</span>
      </v-tooltip>

      <wiki slug="dns-details" />
    </v-toolbar>

    <v-dialog v-if="dialogCancel" v-model="dialogCancel" max-width="360">
      <validation-observer ref="obs" v-slot="{ invalid, validated }">
        <v-card :loading="loadingCancel">
          <v-card-title class="headline">
            {{ $t("cancel termination") }}?
          </v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle class="pa-4">
            {{ $t("canceMessage") }}
          </v-card-subtitle>
          <v-card-text>
            <validation-provider
              vid="comment"
              :name="$t('cancel reason')"
              rules="required"
              v-slot="{ errors, valid, classes }"
            >
              <v-textarea
                name="comment"
                v-model="cancelReason"
                :error-messages="errors"
                :success="valid"
                :label="$t('cancel reason')"
                :class="classes"
                counter="255"
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="dialogCancel = false"
              :disabled="loadingCancel"
            >
              {{ $t("no") }}
            </v-btn>
            <v-btn
              outlined
              @click="cancelTermination"
              :disabled="loadingCancel || invalid || !validated"
            >
              {{ $t("yes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>

    <v-dialog
      v-if="dialogTransferCode"
      v-model="dialogTransferCode"
      max-width="360"
    >
      <validation-observer ref="obs" v-slot="{ invalid, validated }">
        <v-card :loading="loadingTransferCode">
          <v-card-title class="headline">
            {{ $t("request transfer code") }}?
          </v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle class="pa-4">
            <p>{{ $t("transferCodeMessage") }}</p>
            <p>{{ $t("transferCodeMessage2") }}</p>
          </v-card-subtitle>
          <v-card-text>
            <validation-provider
              vid="validity_days"
              :name="$t('validity days')"
              rules="required"
              v-slot="{ errors, valid, classes }"
            >
              <v-select
                name="validity_days"
                v-model="validityDays"
                :items="validityDaysChoices"
                item-text="text"
                item-value="value"
                :error-messages="errors"
                :success="valid"
                :label="$t('validity days')"
                :class="classes"
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="dialogTransferCode = false"
              :disabled="loadingTransferCode"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              outlined
              @click="requestTransferCode"
              :disabled="!validityDays || loadingTransferCode || invalid"
            >
              {{ $t("request") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>

    <v-card-text v-if="domain != null">
      <v-card>
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("status") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>{{ $t("status") }}</th>
                  <td>
                    {{ $t("status_" + domain.status) }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("domain status") }}</th>
                  <td>
                    {{ $t("domStatus_" + domain.domain_status) }}
                  </td>
                </tr>
                <!-- MCF-2704
                <tr
                  v-if="
                    domain.domain_sub_status != null &&
                    domain.domain_sub_status.length > 0
                  "
                >
                  <th>{{ $t("domain sub status") }}</th>
                  <td>
                    <ul>
                      <li
                        v-for="(s, index) in domain.domain_sub_status"
                        :key="index"
                      >
                        {{ $t("subDomStatus_" + s) }}
                      </li>
                    </ul>
                  </td>
                </tr>
                -->
                <tr
                  v-if="
                    domain.irtp_status != null &&
                    domain.irtp_status != '' &&
                    domain.irtp_status != 'none'
                  "
                >
                  <th>{{ $t("IRTP status") }}</th>
                  <td>{{ domain.irtp_status }}</td>
                </tr>
                <tr v-if="domain.irtp_status_losing != null">
                  <th>{{ $t("IRTP status losing") }}</th>
                  <td>{{ domain.irtp_status_losing }}</td>
                </tr>
                <tr v-if="domain.irtp_status_gaining != null">
                  <th>{{ $t("IRTP status gaining") }}</th>
                  <td>{{ domain.irtp_status_gaining }}</td>
                </tr>
                <tr v-if="domain.reg_type != null">
                  <th>{{ $t("registration type") }}</th>
                  <td>{{ $t("regType_" + domain.reg_type) }}</td>
                </tr>
                <tr v-if="domain.order_date != null">
                  <th>{{ $t("order date") }}</th>
                  <td>{{ formatDateTime(domain.order_date) }}</td>
                </tr>
                <tr v-if="domain.execution_date">
                  <th>{{ $t("execution date") }}</th>
                  <td>{{ formatDateTime(domain.execution_date) }}</td>
                </tr>
                <tr v-if="domain.system_in_date != null">
                  <th>{{ $t("in system") }}</th>
                  <td>{{ formatDateTime(domain.system_in_date) }}</td>
                </tr>
                <tr v-if="domain.paid_until_date != null">
                  <th>{{ $t("paid until") }}</th>
                  <td>{{ formatDate(domain.paid_until_date) }}</td>
                </tr>
                <tr v-if="domain.to_be_deleted_date != null">
                  <th>{{ $t("to be deleted date") }}</th>
                  <td>{{ formatDateTime(domain.to_be_deleted_date) }}</td>
                </tr>
                <tr v-if="domain.domain_name != domain.domain_name_idna">
                  <th>{{ $t("IDN domain name") }}</th>
                  <td>
                    {{ domain.domain_name_idna }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>

      <v-card
        class="mt-2"
        v-if="
          domain != null &&
          (domain.transfer_from != null || domain.transfer_to != null)
        "
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("transfer") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-if="domain.transfer_from != null">
                  <th>{{ $t("from") }}</th>
                  <td>{{ domain.transfer_from }}</td>
                </tr>
                <tr v-if="domain.transfer_to">
                  <th>{{ $t("to") }}</th>
                  <td>{{ domain.transfer_to }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>

      <domain-registry-name-server
        v-if="domain != null"
        v-model="domain.nameserver"
        :domain-id="domainId"
        :read-only="domain.to_be_deleted_date != null || isBlocked"
        :default-name-server="defaultNameServer"
        class="mt-2"
      />

      <v-card class="mt-2" v-if="domain != null && zoneEditAllowed">
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("DNS zones") }}</v-toolbar-title>
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="
                  loading ||
                  domain == null ||
                  newZoneDialog ||
                  !domain.dns_zone_allowed ||
                  isBlocked
                "
                @click="newZoneDialog = true"
                icon
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("add zone") }}</span>
          </v-tooltip>
          <wiki slug="dns-zone-edit" />
        </v-toolbar>

        <validation-observer ref="obs" v-slot="{ invalid }">
          <v-card-text v-if="newZoneDialog">
            <v-row>
              <v-col cols="6">
                <validation-provider
                  vid="newZoneName"
                  :name="$t('zone name')"
                  rules="required"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="newZoneName"
                    name="newZoneName"
                    :label="$t('zone name')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  vid="zoneNameServerOption"
                  :name="$t('zone name server')"
                  rules="required"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-radio-group
                    v-model="newZoneNameServerOption"
                    :label="$t('zone name server')"
                    name="zoneNameServerOption"
                    :error-messages="errors"
                    :class="classes"
                  >
                    <template
                      v-for="(item, index) in newZoneNameServerOptionItems"
                    >
                      <v-radio
                        v-if="!item.disabled"
                        :label="$t(item.text)"
                        :value="item.value"
                        :key="index"
                      />
                    </template>
                  </v-radio-group>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <validation-provider
                  vid="newZoneData"
                  :name="$t('zone name')"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-textarea
                    v-model="newZoneData"
                    name="newZoneData"
                    :label="$t('zone data')"
                    :hint="$t('zoneDataHint')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                    auto-grow
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions v-if="newZoneDialog">
            <v-spacer />
            <v-btn text @click="newZoneDialog = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="createZone"
              :loading="loadingNewZone"
              :disabled="loadingNewZone || invalid"
            >
              {{ $t("create zone") }}
            </v-btn>
          </v-card-actions>
        </validation-observer>

        <v-card-text v-if="domain.available_zones.length > 0">
          <v-chip
            v-for="(zone, index) in domain.available_zones"
            :key="index"
            class="mr-2 mb-2"
            :to="{
              name: 'domain-service-zone',
              params: {
                zoneId: zone.zone_id + '@' + zone.nameserver,
                domainId: domainId,
              },
            }"
            :disabled="!domain.dns_zone_allowed"
            color="secondary"
            outlined
          >
            <v-avatar left>
              <v-icon> mdi-dns </v-icon>
            </v-avatar>
            {{ zone.name }} @ {{ zone.nameserver }}
          </v-chip>
        </v-card-text>

        <v-card-text v-else-if="!newZoneDialog">
          <v-alert type="info" border="left" outlined class="mt-4" dense>
            {{ $t("noZones1") }} <v-icon>mdi-plus-circle</v-icon>
            {{ $t("noZones2") }}
          </v-alert>
        </v-card-text>
      </v-card>

      <v-card class="mt-2" v-if="domain != null">
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("contacts") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-expansion-panels multiple>
            <v-expansion-panel
              v-if="domain.admin_c != null"
              @click="getHandle('admin_c')"
            >
              <v-expansion-panel-header class="my-n2">
                <v-row>
                  <v-col cols="4">{{ $t("admin_c") }}</v-col>
                  <v-col cols="8">
                    <v-chip class="my-n4" outlined>
                      <v-avatar left>
                        <v-icon small>mdi-account-circle</v-icon>
                      </v-avatar>
                      {{ domain.admin_c }}
                    </v-chip>
                  </v-col>
                </v-row>
                <template v-slot:actions>
                  <v-btn icon :loading="loadingHandle.admin_c">
                    <v-icon> $expand </v-icon>
                  </v-btn>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <domain-registry-handle
                  :handle="handles.admin_c"
                  v-if="handles.admin_c"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              v-if="domain.owner_c != null"
              @click="getHandle('owner_c')"
            >
              <v-expansion-panel-header class="my-n2">
                <v-row>
                  <v-col cols="4">{{ $t("owner_c") }}</v-col>
                  <v-col cols="8">
                    <v-chip class="my-n4" outlined>
                      <v-avatar left>
                        <v-icon small>mdi-account-circle</v-icon>
                      </v-avatar>
                      {{ domain.owner_c }}
                    </v-chip></v-col
                  >
                </v-row>
                <template v-slot:actions>
                  <v-btn icon :loading="loadingHandle.owner_c">
                    <v-icon> $expand </v-icon>
                  </v-btn>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <domain-registry-handle
                  :handle="handles.owner_c"
                  v-if="handles.owner_c"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              v-if="domain.tech_c != null"
              @click="getHandle('tech_c')"
            >
              <v-expansion-panel-header class="my-n2">
                <v-row>
                  <v-col cols="4">{{ $t("tech_c") }}</v-col>
                  <v-col cols="8">
                    <v-chip class="my-n4" outlined>
                      <v-avatar left>
                        <v-icon small>mdi-account-circle</v-icon>
                      </v-avatar>
                      {{ domain.tech_c }}
                    </v-chip></v-col
                  >
                </v-row>
                <template v-slot:actions>
                  <v-btn icon :loading="loadingHandle.tech_c">
                    <v-icon> $expand </v-icon>
                  </v-btn>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <domain-registry-handle
                  :handle="handles.tech_c"
                  v-if="handles.tech_c"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              v-if="domain.zone_c != null"
              @click="getHandle('zone_c')"
            >
              <v-expansion-panel-header class="my-n2">
                <v-row>
                  <v-col cols="4">{{ $t("zone_c") }}</v-col>
                  <v-col cols="8">
                    <v-chip class="my-n4" outlined>
                      <v-avatar left>
                        <v-icon small>mdi-account-circle</v-icon>
                      </v-avatar>
                      {{ domain.zone_c }}
                    </v-chip></v-col
                  >
                </v-row>
                <template v-slot:actions>
                  <v-btn icon :loading="loadingHandle.zone_c">
                    <v-icon> $expand </v-icon>
                  </v-btn>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <domain-registry-handle
                  :handle="handles.zone_c"
                  v-if="handles.zone_c"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-simple-table class="mt-2">
            <template v-slot:default>
              <tbody>
                <tr v-if="domain.notify_email != null">
                  <th>{{ $t("notify email") }}</th>
                  <td>{{ domain.notify_email }}</td>
                </tr>
                <tr
                  v-if="domain.reseller_id != null && $store.getters.isStaff"
                  class="staff--text"
                >
                  <th>{{ $t("reseller ID") }}</th>
                  <td>
                    {{ domain.reseller_id }}
                  </td>
                </tr>
                <tr
                  v-if="domain.remarks != null && $store.getters.isStaff"
                  class="staff--text"
                >
                  <th>{{ $t("remarks") }}</th>
                  <td>{{ domain.remarks }}</td>
                </tr>
                <tr
                  v-if="
                    domain.general_request_uri != null && $store.getters.isStaff
                  "
                  class="staff--text"
                >
                  <th>{{ $t("general request URI") }}</th>
                  <td>{{ domain.general_request_uri }}</td>
                </tr>
                <tr
                  v-if="
                    domain.abuse_contact_uri != null && $store.getters.isStaff
                  "
                  class="staff--text"
                >
                  <th>{{ $t("abuse contact URI") }}</th>
                  <td>{{ domain.abuse_contact_uri }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>

      <v-card
        class="mt-2"
        v-if="domain != null && domain.tags != null && domain.tags.length > 0"
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("tags") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="(tag, index) in domain.tags" :key="index">
                  <td class="text-center">
                    {{ tag }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        @click="
          $router.push({
            name: 'domain-service',
          })
        "
      >
        {{ $t("domains list") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import formatDateTime from "@/utils/mixins/formatDateTime";
import formatDnsEntry from "@/utils/mixins/formatDnsEntry";
import showErrors from "@/utils/mixins/showErrors";
import DomainRegistryNameServer from "@/components/services/domains/DomainRegistryNameServer";
import DomainRegistryHandle from "@/components/services/domains/DomainRegistryHandle";
import DatePicker from "@/components/basics/DatePicker";
import EmailListInput from "@/components/basics/EmailListInput";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "DomainRegistryDetails",
  components: {
    DomainRegistryNameServer,
    DomainRegistryHandle,
    DatePicker,
    EmailListInput,
    Wiki,
  },
  mixins: [showErrors, formatDate, formatDateTime, formatDnsEntry, isMobile],
  props: {
    domainId: {
      type: [String, Number],
      require: true,
    },
    transferHash: {
      type: String,
      require: false,
      default: null,
      validator: (prop) => typeof prop === "string" || prop == null,
    },
    defaultNameServer: {
      type: Array,
      default: () => [
        "ns1.as8758.net",
        "ns2.as8758.net",
        "ns3.as8758.net",
        "ns4.as8758.net",
        "ns5.as8758.net",
      ],
    },
  },
  data: () => ({
    loading: false,
    domain: null,
    loadingHandle: {
      admin_c: false,
      owner_c: false,
      tech_c: false,
      zone_c: false,
    },
    handles: {
      admin_c: null,
      owner_c: null,
      tech_c: null,
      zone_c: null,
    },
    newZoneDialog: false,
    newZoneName: null,
    newZoneData: null,
    newZoneNameServerOption: "default",
    loadingNewZone: false,
    dialogCancel: false,
    cancelReason: null,
    loadingCancel: false,
    performedCancel: false,
    dialogTransferCode: false,
    loadingTransferCode: false,
    validityDays: 7,
    transferCodeCommited: false,
  }),
  computed: {
    newZoneNameServerOptionItems() {
      return [
        { text: "use default server", value: "default", disabled: false },
        { text: "use current server", value: "current", disabled: false },
        {
          text: "use zone data",
          value: "zone",
          disabled: this.nameServerFromZoneData.length == 0,
        },
      ];
    },
    defaultZoneAvailable() {
      var that = this;
      return (
        this.domain != null &&
        this.domain.available_zones != null &&
        this.domain.available_zones.filter(function (z) {
          return z.name == that.domain.domain_name;
        }).length > 0
      );
    },
    fixedNewZoneData() {
      var that = this;
      var zoneName = this.toAscii(that.newZoneName);
      const reZone = new RegExp("^" + zoneName);
      const reNs = /\s+IN\s+NS\s+/i;
      if (this.newZoneData != null && this.newZoneData.trim() != "")
        return this.newZoneData
          .split(/\r?\n/)
          .filter((line) => !(reZone.test(line) && reNs.test(line)))
          .join("\n");
      return null;
    },
    nameServerFromZoneData() {
      var that = this;
      var zoneName = this.toAscii(that.newZoneName);
      const reZone = new RegExp("^" + zoneName);
      const reNs = /\s+IN\s+NS\s+/i;
      return (this.newZoneData || "")
        .split(/\r?\n/)
        .filter((line) => reZone.test(line) && reNs.test(line))
        .map(function (e) {
          let splitted = e.split(/\s+/);
          return that.trimDotsRight(splitted[splitted.length - 1]);
        });
    },
    zoneEditAllowed() {
      return this.domain != null && "active" == this.domain.domain_status;
    },
    canCancel() {
      var today = new Date();
      return (
        !this.performedCancel &&
        this.domain != null &&
        this.domain.product_id != null &&
        this.domain.to_be_deleted_date != null &&
        this.$moment(this.domain.to_be_deleted_date).isAfter(today)
      );
    },
    canTransfer() {
      return (
        this.domain != null &&
        this.domain.product_id != null &&
        this.domain.transfer_code_available &&
        !this.domain.has_open_workers
      );
    },
    validityDaysChoices() {
      return [
        { text: this.$i18n.t("1 day"), value: 1 },
        { text: this.$i18n.t("3 days"), value: 3 },
        { text: this.$i18n.t("7 days"), value: 7 },
        { text: this.$i18n.t("10 days"), value: 10 },
        { text: this.$i18n.t("14 days"), value: 14 },
      ];
    },
    isBlocked() {
      return this.domain == null || this.domain.blocked || false;
    },
  },
  watch: {
    domainId: {
      immediate: true,
      handler(value) {
        if (value != null) this.getDomain();
        else this.domain = null;
        this.performedCancel = false;
      },
    },
    transferHash: {
      immediate: true,
      handler(value) {
        if (
          value != null &&
          (!this.transferCodeCommited || this.transferCodeCommited != value)
        )
          this.commitTransferCode();
      },
    },
  },
  methods: {
    getDomain() {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/dns/registry/" + this.domainId)
        .then((response) => {
          that.domain = response.data;
          that.newZoneName = that.domain.domain_name;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    getHandle(type) {
      if (this.handles[type] == null) {
        var that = this;
        this.loadingHandle[type] = true;
        this.$http
          .get("services/dns/registry/" + this.domainId + "/handle", {
            params: { type: type },
          })
          .then((response) => {
            that.handles[type] = response.data;
          })
          .catch((err) => {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          })
          .finally(function () {
            that.loadingHandle[type] = false;
          });
      }
    },
    createZone() {
      var that = this;
      this.loadingNewZone = true;
      var data = {
        kind: "Native",
        name: this.newZoneName,
      };
      if (this.newZoneNameServerOption == "current")
        data.nameservers = this.domain.nameserver.map(function (ns) {
          let parts = (ns || "").split(/\s+/);
          return parts[0];
        });
      else if (this.newZoneNameServerOption == "default")
        data.nameservers = this.defaultNameServer;
      else if (this.newZoneNameServerOption == "zone")
        data.nameservers = this.nameServerFromZoneData;
      if (this.fixedNewZoneData) data.zone = this.fixedNewZoneData;
      this.$http
        .post("services/dns/forward", data)
        .then((response) => {
          that.newZoneDialog = false;
          that.getDomain();
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingNewZone = false;
        });
    },
    cancelTermination() {
      var that = this;
      this.loadingCancel = true;
      this.$http
        .post(
          "services/dns/registry/" + this.domainId + "/cancel-termination",
          {
            comment: this.cancelReason,
          }
        )
        .then((response) => {
          that.dialogCancel = false;
          that.performedCancel = true;
          that.getDomain();
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingCancel = false;
        });
    },
    requestTransferCode() {
      var that = this;
      this.loadingTransferCode = true;
      this.$http
        .post("services/dns/registry/" + this.domainId + "/transfer-code", {
          validity_days: this.validityDays,
        })
        .then((response) => {
          this.$snotify.confirm(
            this.$i18n.t("transferCodeMessage3"),
            this.$i18n.t("transfer code requested"),
            {
              timeout: 360000,
              showProgressBar: false,
              closeOnClick: false,
              buttons: [
                {
                  text: this.$i18n.t("ok"),
                  action: (toast) => {
                    this.$snotify.remove(toast.id);
                  },
                  bold: true,
                },
              ],
            }
          );
          that.dialogTransferCode = false;
          that.getDomain();
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingTransferCode = false;
        });
    },
    commitTransferCode() {
      var that = this;
      this.loadingTransferCode = true;
      this.$http
        .post("services/dns/registry/" + this.domainId + "/transfer-code", {
          transfer_hash: this.transferHash,
        })
        .then((response) => {
          this.$snotify.confirm(
            this.$i18n.t("transferCodeMessage4"),
            this.$i18n.t("transfer code confirmed"),
            {
              timeout: 360000,
              showProgressBar: false,
              closeOnClick: false,
              buttons: [
                {
                  text: this.$i18n.t("ok"),
                  action: (toast) => {
                    this.$snotify.remove(toast.id);
                  },
                  bold: true,
                },
              ],
            }
          );
          that.$router.push({ name: "domain-service-details" });
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.transferCodeCommited = that.transferHash;
          that.loadingTransferCode = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "zone name server": "Zone name server",
    "use zone data": "use zone data",
    "use default server": "use default server",
    "use current server": "use current server",
    "open product": "open product",
    "status": "Status",
    "dates": "Dates",
    "contacts": "Contacts",
    "domain status": "Domain status",
    "domain sub status": "Domain sub status",
    "IRTP status": "IRTP status",
    "IRTP status losing": "IRTP status losing",
    "IRTP status gaining": "IRTP status gaining",
    "registration type": "Registration type",
    "order date": "Order date",
    "execution date": "Execution date",
    "in system": "In system",
    "paid until": "Paid until",
    "to be deleted date": "To be deleted date",
    "reseller ID": "Reseller ID",
    "admin_c": "Administrative contact",
    "owner_c": "Owner contact",
    "tech_c": "Technical contact",
    "zone_c": "Zone contact",
    "notify email": "Notify email",
    "remarks": "Remarks", 
    "general request URI": "General request URI",
    "abuse contact URI": "Abuse contact URI",
    "transfer": "Transfer",
    "from": "From",
    "to": "To",
    "tags": "tags",
    "domains list": "Domains list",
    "deletion date": "deletion date",
    "notify email": "notify email",
    "DNS zones": "DNS Zones",
    "add zone": "add zone",
    "zone data": "Zone Data",
    "zone name": "Zone Name",
    "create zone": "create zone",
    "zoneDataHint": "optional BIND zone file data",
    "reload domain": "reload domain",
    "noZones1": "No zones available, click to",
    "noZones2": "to add a zone.",
    "use nameservers": "use nameservers",
    "cancel termination": "cancel termination",
    "canceMessage": "Termination processing will be canceled immediately!",
    "cancel reason": "Cancel reason",
    "request transfer code": "Request transfer code",
    "transferCodeMessage": "To move your domain to another provider, you usually need a so-called transfer code. With this code, the new provider can take over your domain. Please keep the validity of the transfer code as short as possible to prevent possible misuse!",
    "transferCodeMessage2": "After applying, you will receive an email with a confirmation link. When you open the confirmation link, the transfer code will be sent to you by email.",
    "transferCodeMessage3": "You will now receive an email with a confirmation link.",
    "transferCodeMessage4": "An email with the desired transfer code will be sent to you.",
    "validity days": "Validity days",
    "1 day": "1 day",
    "3 days": "3 days",
    "7 days": "7 days",
    "10 days": "10 days",
    "14 days": "14 days",
    "cancel": "Cancel",
    "request": "Request",
    "transfer code confirmed": "Transfer code confirmed!",
    "transfer code requested": "Transfer code requisted!",
    "yes": "Yes",
    "no": "No",
    "ok": "Ok",
    "IDN domain name": "IDN domain name (PunyCode)",

    "status_kk_laeuft": "Transfer is running",
    "status_kk_nack": "Transfer failed",
    "status_kk_nack_code_falsch": "Transfer failed: invalid authentication code",
    "status_kk_nack_denied": "Transfer was declined",
    "status_kk_nack_dns_falsch": "Transfer failed: nameserver error",
    "status_kk_nsfehler": "Transfer failed: nameserver error",
    "status_kk_ok": "Transfer successfully completed",
    "status_kk_ok_com": "Transfer successfully completed: no subsequent update",
    "status_kk_und_update_ok": "Transfer and update successfully completed",
    "status_loeschung_laeuft_off": "Domain deletion has been initiated",
    "status_loeschung_off": "Domain has been deleted",
    "status_on_hold_off": "Domain in transit",
    "status_reg_exists": "Registration failed: domain already exists",
    "status_reg_laeuft": "Registration has started",
    "status_reg_nack": "Registration failed",
    "status_reg_nack_dns_falsch": "Registration failed due to a name server error",
    "status_reg_nsfehler": "Registration failed due to a name server error",
    "status_reg_ok": "Registration completed successfully",
    "status_starttermin_order": "Register / transfer on a specific date",
    "status_vorreg2_order": "Binding reservation",
    "status_vorreg_order": "Non-binding reservation",
    "status_zu_fremdprovider_uebernommen_off": "Domain was transferred to another provider",
    "status_update_ok": "Update successful",
    "status_update_laeuft": "Update is running",
    "status_update_nack_wait": "Update refused, try again",
    "status_update_nack": "Update failed",
    "status_update_nsfehler": "Name server update failed",
    "status_processing": "Processing",

    "domStatus_scheduledReg": "Domain is reserved for registration",
    "domStatus_scheduledTransfer": "Domain is reserved for the transfer",
    "domStatus_pendingReg": "Domain is being registered",
    "domStatus_pendingTransfer": "Domain is currently being transferred to our system",
    "domStatus_active": "Domain is in your active inventory",
    "domStatus_failed": "Domain was registered / transferred with (name server) errors. Depending on how the registry is used, the domain may be deleted",
    "domStatus_pendingDelete": "Domain is being deleted",
    "domStatus_deleted": "Domain was deleted",
    "domStatus_pendingTransit": "Domain is currently being put into transit",
    "domStatus_transit": "Domain was put in transit",
    "domStatus_transferredOut": "Domain was moved to another registrar",
    "domStatus_failedTransfer": "Domain could not be transferred successfully",
    "domStatus_failedReg": "Domain could not be registered successfully",
    "domStatus_unknown": "The status cannot currently be determined, please contact support",
    
    "subDomStatus_transferLock": "Transfer lock for the domain is set",
    "subDomStatus_requestedTransferOut": "There is a transfer request for the domain",
    "subDomStatus_pendingTransferOut": "The domain is being transferred",
    "subDomStatus_pendingUpdate": "The domain is currently being updated",
    "subDomStatus_failedUpdate": "An update failed",
    "subDomStatus_failedNSUpdate": "An update of the name server failed",
    "subDomStatus_successfulUpdate": "Update carried out successfully",
    "subDomStatus_scheduledDelete": "The domain is marked for deletion",
    "subDomStatus_scheduledTransit": "The domain is reserved for transit",
    "subDomStatus_disabled": "The domain was deactivated due to incorrect owner validation",

    "regType_KK": "Transfer",
    "regType_REG": "Registration"

  },
  "de": {
    "zone name server": "Zonen Nameserver",
    "use zone data": "nutze Zonen Daten",
    "use default server": "nutze Default Server",
    "use current server": "nutze aktuelle Server",
    "open product": "Produkt öffnen",
    "status": "Status",
    "dates": "Daten",
    "contacts": "Kontakte",
    "domain status": "Domain Status",
    "domain sub status": "Domain Unterstatus",
    "IRTP status": "IRTP Status",
    "IRTP status losing": "IRTP Status verloren",
    "IRTP status gaining": "IRTP Status gewonnen",
    "registration type": "Registrierungstyp",
    "order date": "Bestelldatum",
    "execution date": "Ausführungsdatum",
    "in system": "Im System",
    "paid until": "Bezahlt bis",
    "to be deleted date": "Datum der Löschung",
    "reseller ID": "Reseller ID",
    "admin_c": "Administrativer Kontakt",
    "owner_c": "Besitzer Kontakt",
    "tech_c": "Technischer Kontakt",
    "zone_c": "Zonen Kontakt",
    "notify email": "Benachrichtigungs Email",
    "remarks": "Anmerkung", 
    "general request URI": "Generelle Kontakt URI",
    "abuse contact URI": "Missbrauchs Kontakt URI",
    "transfer": "Übertragung",
    "from": "Von",
    "to": "Nach",
    "tags": "Tags",
    "domains list": "Domain Liste",
    "deletion date": "Löschdatum",
    "notify email": "Benachrichtigungs Email",
    "DNS zones": "DNS Zonen",
    "add zone": "Zone hinzufügen",
    "zone data": "Zonen Daten",
    "zone name": "Zonen Name",
    "create zone": "Zone anlegen",
    "zoneDataHint": "optionale BIND Zonen Datei Daten",
    "reload domain": "Domain erneut laden",
    "noZones1": "Keine Zonen verfügbar, klicken Sie auf",
    "noZones2": "um eine Zone anzulegen.",
    "use nameservers": "benutze Nameserver",
    "cancel termination": "Kündigung abbrechen",
    "canceMessage": "Die Kündigung wird umgehend abgebrochen!",
    "cancel reason": "Abbruchbegründung",
    "request transfer code": "Transfer Code beantragen",
    "transferCodeMessage": "Für den Umzug Ihrer Domain zu einem anderen Anbieter, benötigen Sie in der Regel einen sogenanten Transfer Code. Mit diesem Code kann der neue Anbieter Ihre Domain übernehmen. Bitte halten Sie die Gültigkeit des Transfer Codes to kurz wie möglich um möglichen Misbrauch vorzubeugen!",
    "transferCodeMessage2": "Nach der Beantragung erhalten Sie eine Email mit einem Bestätigungs-Link. Mit dem öffnen des Bestätigungs-Links wird Ihnen der Transfer Code per Email zugesannt.",
    "transferCodeMessage3": "Sie erhalten nun eine Email mit einem Bestätigungs-Link.",
    "transferCodeMessage4": "Eine Email mit dem gewünschten Tranfer Code wird Ihnen zugestellt.",
    "validity days": "Gültigskeitstage",
    "1 day": "1 Tag",
    "3 days": "3 Tage",
    "7 days": "7 Tage",
    "10 days": "10 Tage",
    "14 days": "14 Tage",
    "cancel": "Abbrechen",
    "request": "Beantragen",
    "transfer code confirmed": "Transfer Code bestätigt!",
    "transfer code requested": "Transfer Code beantragt!",
    "yes": "Ja",
    "no": "Nein",
    "ok": "Ok",
    "IDN domain name": "IDN Domain Name (PunyCode)",

    "status_kk_laeuft": "Transfer läuft",
    "status_kk_nack": "Transfer fehlgeschlagen",
    "status_kk_nack_code_falsch": "Transfer fehlgeschlagen: AuthCode falsch",
    "status_kk_nack_denied": "Transfer wurde abgelehnt",
    "status_kk_nack_dns_falsch": "Transfer fehlgeschlagen Nameserverfehler",
    "status_kk_nsfehler": "Transfer fehlgeschlagen Nameserverfehler",
    "status_kk_ok": "Transfer erfolgreich abgeschlossen",
    "status_kk_ok_com": "Transfer erfolgreich abgeschlossen: kein nachfolgendes Update",
    "status_kk_und_update_ok": "Transfer und Update erfolgreich abgeschlossen",
    "status_loeschung_laeuft_off": "Domain-Löschung wurde eingeleitet",
    "status_loeschung_off": "Domain wurde gelösch",
    "status_on_hold_off": "Domain in Transit",
    "status_reg_exists": "Registrierung fehlgeschlagen: Domain existiert bereits",
    "status_reg_laeuft": "Registrierung wurde eingeleitet",
    "status_reg_nack": "Registrierung fehlgeschlagen",
    "status_reg_nack_dns_falsch": "Registrierung aufgrund eines Nameserverfehlers fehlgeschlagen",
    "status_reg_nsfehler": "Registrierung aufgrund eines Nameserverfehlers fehlgeschlagen",
    "status_reg_ok": "Registrierung erfolgreich abgeschlossen",
    "status_starttermin_order": "Registrieren / Transferieren zu einem bestimmten Termin",
    "status_vorreg2_order": "Verbindliche Vormerkung",
    "status_vorreg_order": "Unverbindliche Vormerkung",
    "status_zu_fremdprovider_uebernommen_off": "Domain wurde zu einem anderen Provider übertragen",
    "status_update_ok": "Update erfolgreich",
    "status_update_laeuft": "Update läuft",
    "status_update_nack_wait": "Update abgelehnt, erneut versuchen",
    "status_update_nack": "Update fehlgeschlagen",
    "status_update_nsfehler": "Nameserver-Update fehlgeschlagen",
    "status_processing": "In Bearbeitung",
   
    "domStatus_scheduledReg": "Domain ist zur Registrierung vorgemerkt",
    "domStatus_scheduledTransfer": "Domain ist für den Transfer vorgemerkt",
    "domStatus_pendingReg": "Domain wird gerade registriert",
    "domStatus_pendingTransfer": "Domain wird gerade in unser System transferiert",
    "domStatus_active": "Domain befindet sich in Ihrem aktiven Bestand",
    "domStatus_failed": "Domain wurde mit (Nameserver-) Fehlern registriert/transferiert. Je nach Bestimmung der Registry kann es zu einer Löschung der Domain kommen",
    "domStatus_pendingDelete": "Domain wird gerade gelöscht",
    "domStatus_deleted": "Domain wurde gelöscht",
    "domStatus_pendingTransit": "Domain wird gerade in den Transit gegeben",
    "domStatus_transit": "Domain wurde in den Transit gegeben",
    "domStatus_transferredOut": "Domain wurde zu einem anderen Registrar weggezogen",
    "domStatus_failedTransfer": "Domain konnte nicht erfolgreich transferiert werden",
    "domStatus_failedReg": "Domain konnte nicht erfolgreich registriert werden",
    "domStatus_unknown": "Der Status kann aktuell nicht bestimmt werden, bitte wenden Sie sich an den Support",

    "subDomStatus_transferLock": "Transfer-Sperre für die Domain ist gesetzt",
    "subDomStatus_requestedTransferOut": "Es liegt eine Transferanfrage für die Domain vor",
    "subDomStatus_pendingTransferOut": "Die Domain wird gerade transferiert",
    "subDomStatus_pendingUpdate": "es wird gerade ein Update für die Domain durchgeführt",
    "subDomStatus_failedUpdate": "ein Update ist fehlgeschlagen",
    "subDomStatus_failedNSUpdate": "ein Update der Nameserver ist fehlgeschlagen",
    "subDomStatus_successfulUpdate": "Update erfolgreich durchgeführt",
    "subDomStatus_scheduledDelete": "die Domain ist zur Löschung vorgemerkt",
    "subDomStatus_scheduledTransit": "die Domain ist für den Transit vorgemerkt",
    "subDomStatus_disabled": "die Domain wurde aufgrund fehlerhafter Inhabervalidierung deaktiviert",

    "regType_KK": "Transfer",
    "regType_REG": "Registrierung"
  }
}
</i18n>
