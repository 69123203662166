<template>
  <v-card v-if="domainId != null">
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("nameservers") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :disabled="readOnly"
            @click="
              nameserver = [...defaultNameServer];
              $emit('input', [...nameserver]);
            "
            icon
          >
            <v-icon>mdi-server</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("replace with default servers") }}</span>
      </v-tooltip>
      <wiki slug="dns-domain-ns" />
    </v-toolbar>
    <validation-observer ref="obs" v-slot="{ invalid, dirty }">
      <v-card-text>
        <validation-provider
          vid="nameserver"
          :name="$t('nameserver')"
          rules="required|nameserver_list"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-combobox
            v-model="nameserver"
            name="nameserver"
            :error-messages="errors"
            :success="dirty && valid"
            :class="classes"
            :items="defaultNameServer"
            multiple
            deletable-chips
            :clearable="!isMobile"
            chips
            @input="normalizeNameServer()"
          >
            <template v-slot:selection="{ item }">
              <v-chip
                outlined
                close
                @click:close="removeNS(item)"
                :disabled="readOnly"
                color="secondary"
                close-icon="mdi-server-remove"
              >
                {{ item }}
              </v-chip>
            </template>
          </v-combobox>
        </validation-provider>
        <v-alert type="info" border="left" outlined class="mt-4" dense>
          {{ $t("nameServerChangeWarning") }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="saveNameServer()"
          :loading="loading"
          :disabled="invalid || loading || readOnly"
          text
        >
          <v-icon left> mdi-content-save-move </v-icon>
          {{ $t("save nameserver") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import { isValidNameserver } from "@/plugins/validate.js";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "DomainRegistryNameServer",
  mixins: [showErrors, isMobile],
  components: {
    Wiki,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    domainId: {
      type: [String, Number],
      require: true,
    },
    readOnly: {
      type: Boolean,
      require: false,
      default: false,
    },
    defaultNameServer: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    nameserver: [],
    loading: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value != null) this.nameserver = [...value];
        else this.nameserver = [];
      },
    },
  },
  methods: {
    removeNS(value) {
      this.nameserver = this.nameserver.filter(function (e) {
        return e != value;
      });
      this.$emit("input", [...this.nameserver]);
    },
    normalizeNameServer() {
      if (this.nameserver != null) {
        this.nameserver = this.nameserver
          .map(function (e) {
            // to allow IDN domain names set punyDecode=true
            return isValidNameserver(e, false) ? e : null;
          })
          .filter(function (e) {
            return e != null;
          });
      } else this.nameserver = [];
      this.$emit("input", [...this.nameserver]);
    },
    saveNameServer() {
      var that = this;
      this.loading = true;
      this.$http
        .patch("services/dns/registry/" + this.domainId, {
          nameserver: this.nameserver,
        })
        .then((response) => {
          that.$emit("input", [...response.data.nameserver]);
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "nameservers": "Nameservers",
    "nameserver": "Nameserver",
    "replace with default servers": "replace with default servers",
    "nameServerChangeWarning": "Please make sure that you have moved your old DNS records to the new servers before changing the name server.",
    "save nameserver": "save nameserver"
  },
  "de": {
    "nameservers": "Nameserver",
    "nameserver": "Nameserver",
    "replace with default servers": "mit Default Servern ersetzen",
    "nameServerChangeWarning": "Bitte stellen Sie sicher, dass Sie vor Änderung der Nameserver, Ihre alten DNS Einträge auf die neuen Server umgezogen haben.",
    "save nameserver": "Nameserver speichern"
  }
}
</i18n>
