<template>
  <v-simple-table dense>
    <template v-slot:default>
      <tbody v-if="handle != null">
        <tr v-if="handle.company_name">
          <th>{{ $t("company name") }}</th>
          <td>{{ handle.company_name }}</td>
        </tr>
        <tr v-if="handle.company_id != null">
          <th>{{ $t("company id") }}</th>
          <td>{{ handle.company_id }}</td>
        </tr>
        <tr v-if="handle.trademark_id != null">
          <th>{{ $t("trademark id") }}</th>
          <td>{{ handle.trademark_id }}</td>
        </tr>
        <tr v-if="handle.first_name != null">
          <th>{{ $t("first name") }}</th>
          <td>{{ handle.first_name }}</td>
        </tr>
        <tr v-if="handle.last_name != null">
          <th>{{ $t("last name") }}</th>
          <td>{{ handle.last_name }}</td>
        </tr>
        <tr v-if="handle.street != null">
          <th>{{ $t("street") }}</th>
          <td>{{ handle.street }}</td>
        </tr>
        <tr v-if="handle.city != null">
          <th>{{ $t("city") }}</th>
          <td>{{ handle.city }}</td>
        </tr>
        <tr v-if="handle.country != null">
          <th>{{ $t("country") }}</th>
          <td>{{ handle.country }}</td>
        </tr>
        <tr v-if="handle.phone != null">
          <th>{{ $t("phone") }}</th>
          <td>{{ handle.phone }}</td>
        </tr>
        <tr v-if="handle.fax != null">
          <th>{{ $t("fax") }}</th>
          <td>{{ handle.fax }}</td>
        </tr>
        <tr v-if="handle.email != null">
          <th>{{ $t("email") }}</th>
          <td>{{ handle.email }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "DomainRegistryHandle",
  props: {
    handle: {
      type: Object,
      required: true,
    },
  },
};
</script>

<i18n>
{
  "en": {
    "company name": "company name",
    "company id": "company ID",
    "trademark id": "trademark ID",
    "first name": "first name",
    "last name": "last name",
    "street": "street",
    "zip code": "zip code",
    "city": "city",
    "country": "country",
    "phone": "phone",
    "fax": "fax",
    "email": "email"
  },
  "de": {
    "company name": "Firmanename",
    "company id": "Handelsregisternummer",
    "trademark id": "Markennummer",
    "first name": "Vorname",
    "last name": "Nachname",
    "street": "Strasse",
    "zip code": "PLZ",
    "city": "Stadt",
    "country": "Land",
    "phone": "Telefon",
    "fax": "Fax",
    "email": "Email"
  }
}
</i18n>
