const punycode = require('punycode/');
const dns = require("@/utils/dns");

export default {
  methods: {
    trimDots(str) {
      var regex =  /^[\s\uFEFF\xA0\.]+|[\s\uFEFF\xA0\.]+$/g
      return str.replace(regex, '');
    },
    trimDotsLeft(str) {
      var regex =  /^[\s\uFEFF\xA0\.]+/g
      return str.replace(regex, '');
    },
    trimDotsRight(str) {
      var regex =  /[\s\uFEFF\xA0\.]+$/g
      return str.replace(regex, '');
    },
    trimQuotationMarks(str) {
      var regex =  /^[\s\uFEFF\xA0\"]+|[\s\uFEFF\xA0\"]+$/g
      return str.replace(regex, '');
    },
    trimQuotationMarksLeft(str) {
      var regex =  /^[\s\uFEFF\xA0\"]+/g
      return str.replace(regex, '');
    },
    trimQuotationMarksRight(str) {
      var regex =  /[\s\uFEFF\xA0\"]+$/g
      return str.replace(regex, '');
    },
    toUniCode(s) {
      return punycode.toUnicode(s);
    },
    toAscii(s) {
      return punycode.toASCII(s);
    },
    formatDnsEntry(name, type, removeTailingDot = true, punyDecode = true) {
      var s = name || '';
      if (removeTailingDot && dns.isTailingDotType(type || '')) {
        s = this.trimDotsRight(s)
      }
      if ((type || '').toUpperCase() == 'TXT') {
        s =  this.trimQuotationMarks(s);
      }
      if (punyDecode)
        s = this.toUniCode(s);
      return s;
    },
    formatDnsEntryReverse(name, type) {
      var s = name || '';
      if (dns.isTailingDotType(type || '')) {
        s = this.trimDotsRight(s)
        s = s + '.';
      }
      if ((type || '').toUpperCase() == 'TXT') {
        s = this.trimQuotationMarks(s);
        s = '"' + s + '"';
      }
      return s;
    },
  }
};
